// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './About.css';

// img imports
import profilePic from './img/shehabProfilePic.png';
import staffPicOne from './img/staffPicOne.png';
import staffPicTwo from './img/staffPicTwo.png';
import staffPicThree from './img/staffPicThree.png';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';

const Home = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book />

            {/* Header Div */}

            <div className='aboutUsHeader'>
                <div className="aboutText">
                    <h1>About Us</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
            </div>

            {/* Dr Awadallah */}

            <div className="chiropractorDiv">
                <div className="col">
                    <h3>Dr. Shehab Awadallah, D.C.</h3>
                    <p>Dr. Shehab Awadallah, D.C. is a first generation American who has been driven since a young age to provide exceptional medical care. As a result, he began as an associate chiropractor right after graduating and within a year became Clinical Director for an institution with over 39 clinics statewide! He is thrilled to finally open his own practice and put all his knowledge and experience to provide for Orland Park and the surrounding areas.</p>
                </div>
                <div className="col">
                    <img src={profilePic} alt="Shehab Awadallah" id="shehab" />
                </div>
            </div>
            <div className="chiropractorBody">
                <p>Dr. Shehab earned his bachelor’s degree in Biological Sciences from the University of Illinois at Chicago, where he was on the Dean’s List, earned the National Academic Award and held Honors Status. Following his undergraduate degree, he went on to achieve his Doctor of Chiropractic from National University of Health Sciences and is Chiropractic State Board Certified (Parts I, II, III, and IV). Dr. Awadallah has had many opportunities in his chiropractic profession to learn and adapt his style of chiropractic that blends many components of medicine. This includes chiropractic, acupuncture, cupping, physical therapy, trigger point therapy, myofascial release, IV hydration, PRP, and stem cells. In his continual search to adapt to the constant changes in medicine, Dr. Awadallah furthered his education with a Master of Nursing. While running his own chiropractic office he works in an Intensive Care Unit (ICU) with the hope to finish with a Doctor of Nursing Practice. This will allow him to do more for the community by using multiple facets of medicine and allows a great referral base for his patients if needed to seek further medical care.</p>
                <div id="secondParagraph">
                    <p>In Dr. Shehab Awadallah’s spare time, he enjoys traveling, sight-seeing, hiking, art, and UFC martial arts. More importantly, spending time with his family and loved ones. Dr. Awadallah draws his inspiration from the quote, “Medicine is the study of what causes man to die. Chiropractic is the study of health and what causes man to live.” -B.J. Palmer
                    </p>
                </div>
            </div>

            {/* Meet The Team */}

            <div className="meetTheTeamHeader">
                <h1>Meet The Team</h1>
            </div>

            <div className="aboutConstruction">
                <p>Coming Soon!</p>
            </div>

            <div className="meetTheTeamDiv">
                <div className="col">
                    <div className="teamDiv">
                        <img src={staffPicOne} alt="staff pic" className="staffPic" id="staffPicOne" />
                        <div className="teamDivText">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam eum adipisci blanditiis cum rerum, eos accusantium maiores inventore qui, ducimus, amet assumenda. Dolores beatae fugiat laudantium vero tempora atque id?</p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="teamDiv">
                        <img src={staffPicTwo} alt="staff pic" className="staffPic" />
                        <div className="teamDivText">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam eum adipisci blanditiis cum rerum, eos accusantium maiores inventore qui, ducimus, amet assumenda. Dolores beatae fugiat laudantium vero tempora atque id?</p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="teamDiv">
                        <img src={staffPicThree} alt="staff pic" className="staffPic" />
                        <div className="teamDivText">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam eum adipisci blanditiis cum rerum, eos accusantium maiores inventore qui, ducimus, amet assumenda. Dolores beatae fugiat laudantium vero tempora atque id?</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Providers */}

            <Providers />

            {/* Map / Office Hours */}

            <Map />

        </>
    )
}

export default Home;