// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Providers.css';

// img imports
import bluecross from './img/blueCrossLogo.png';
import unitedLogo from './img/unitedHealthcareLogo.png';
import aetnaLogo from './img/aetnaLogo.png';
import medicareLogo from './img/medicareLogo.png';
import cignaLogo from './img/cignaLogo.png';

const Providers = (props) => {

    return (

        <>

            {/* Providers */}

            <div className="providersDiv">
                <div className="providerHeader">
                    <h3>PROVIDERS</h3>
                </div>

                {/* Providers Div (Desktop) */}

                <div className="providersDesktop">
                    <div className="providerCol">
                        <img src={cignaLogo} alt="cigna logo" className="providerLogo" id="cigna" />
                    </div>
                    <div className="providerCol">
                        <img src={aetnaLogo} alt="aetna logo" className="providerLogo" id="aetna" />
                    </div>
                    <div className="providerCol">
                        <img src={unitedLogo} alt="united logo" className="providerLogo" id="united" />
                    </div>
                    <div className="providerCol">
                        <img src={medicareLogo} alt="medicare logo" className="providerLogo" id="medicare" />
                    </div>
                    <div className="providerCol">
                        <img src={bluecross} alt="bluecross logo" className="providerLogo" id="blue" />
                    </div>
                </div>

                {/* Providers Carousel (Mobile) */}
                <div id="carouselControls" class="carousel carousel-dark slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active carouselImg">
                            <img src={bluecross} class="d-block w-100" alt="bluecross logo" id="bluecrossLogo"></img>
                        </div>
                        <div class="carousel-item carouselImg">
                            <img src={unitedLogo} class="d-block w-100 unitedLogo" alt="united logo"></img>
                        </div>
                        <div class="carousel-item carouselImg">
                            <img src={aetnaLogo} class="d-block w-100 aetnaLogo" alt="aetna logo"></img>
                        </div>
                        <div class="carousel-item carouselImg">
                            <img src={medicareLogo} class="d-block w-100 medicareLogo" alt="medicare logo"></img>
                        </div>
                        <div class="carousel-item carouselImg">
                            <img src={cignaLogo} class="d-block w-100 cignaLogo" alt="cigna logo" id="cignaLogoMobile"></img>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>

                {/* Promotional Offers */}

                <div className="promotionsDiv">
                    <p>* Promotional offers may not be used with providers of Medicare, Medicaid, or Tricare. It is against the law to provide promotional service as dictated by these carriers. Please call for further information and options!</p>
                </div>

            </div>

        </>
    )
}

export default Providers;