// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Footer.css';

// img imports
import facebook from './img/facebookLogo.png';
import instagram from './img/instagramLogo.png';
import twitter from './img/twitterLogo.png';

const Footer = (props) => {

    return (

        <>

            {/* footer */}

            <div className="footerDiv">
                <div className="footercol">
                    <div className="footerInfo">
                        <p>Bounce Back Chiropractic</p>
                        <p>16525 106th Ct</p>
                        <p>Orland Park, IL 60467</p>
                        <p>(708) 590 - 6302</p>
                        <p>Website by <a href="https://dixonwebdev.com" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>Dixon Web Development</a></p>
                    </div>
                </div>
                <div className="footercol">
                    <div className="footerSocial">
                        <a href="https://www.facebook.com/profile.php?id=100083358691602" target="_blank" rel="noopener noreferrer"><img src={facebook} alt='facebook' className="logoSize"></img></a>
                        <a href="https://www.instagram.com/bounce.back.chiropractic/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt='instagram' className="logoSize"></img></a>
                        <img src={twitter} alt='twitter' className="logoSize"></img>
                    </div>
                    <div className="footerLinks">
                        <p id="copyrightText">Copyright &copy; 2022 Bounce Back Chiropractic. All rights reserved.</p>
                        <p> | </p>
                        <p>privacy</p>
                        <p> | </p>
                        <p>site map</p>
                    </div>
                    <div className="footerLinksMobile">
                        <div className="mobileLinksText">
                            <p>Privacy</p>
                            <br></br>
                            <p> | </p>
                            <br></br>
                            <p>Site Map</p>
                        </div>
                        <div className="mobileLinksCopyright">
                            <p>Copyright &copy; 2022 Bounce Back Chiropractic. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Footer;