// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './NeckPain.css';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';
import BackButton from "./BackButton";

// img imports
import neckpainImage from './img/neckPain.png';

const NeckPain = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book/>

            {/* Header Div */}

            <div className='neckpainHeader'>
                <div className="neckpainText">
                    <h1>Neck Pain</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <BackButton/>
            </div>

            {/* neckpain */}

            <div className="neckpainImageDiv">
                <img src={neckpainImage} alt="neckpain pic" id="neckpainPic"/>
            </div>

            <div className="neckpainDiv">
                <p>Neck pain is one of the most common conditions experienced among patients in our population due to our standard of living conditions. As a result of chronic sitting behind a computer, on tablets/iPads, or cellular phones, we develop structural misalignments on the very fine nerves in your neck. Conditions such as a cervical disc and whiplash from trauma can contribute to this also.</p>
                <p>Simple tasks such as bathing, brushing teeth or hair, applying makeup, checking your blind spots while driving, or checking on kids in the back seat while in the car become excruciating and may be dangerous. Many patients will seek pain solutions on their own covering it with “band-aid solutions.” This pain may have become your normal or you may say this is a process of aging, but it should not have to be tolerated.</p>
                <p>Our team will individualize a care plan dedicated to your neck pain based on your condition to find the root cause to get you better and stay pain-free. Each patient’s condition is unique, and your condition will be treated effectively/efficiently. Let’s <span id="bounceBackText">BOUNCE BACK</span> into health and your full potential!</p>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map / Office Hours */}

            <Map/>

        </>
    )
}

export default NeckPain;