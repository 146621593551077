// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './SportsInjury.css';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';
import BackButton from "./BackButton";

// img imports
import sportsInjuryPic from './img/sportsInjury.png';

const SportsInjury = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book/>

            {/* Header Div */}

            <div className='sportsInjuryHeader'>
                <div className="sportsInjuryText">
                    <h1>Sports Injury</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <BackButton/>
            </div>

            {/* Sports Injury */}

            <div className="sportsInjuryImageDiv">
                <img src={sportsInjuryPic} alt="personal injury pic" id="sportsInjuryPic"/>
            </div>

            <div className="sportsInjuryDiv">
                <p>Sports injuries are common and sadly most often ignored until it becomes a severe, nagging condition. Injuries can happen to any joint in the body. Some examples of a sports injury can occur while being tackled in football, rolling your ankle while running, or simply lifting weights improperly during your workout. Other conditions can be concussions, sprains to ligaments, muscle strain, or tears of ligaments, tendons and menisci. </p>
                <p>No matter how it happened or what the condition is, it is extremely important to get it treated before is causes permanent damage and structural change.</p>
                <p>Our team will individualize a care plan dedicated to your sports injuries based on your condition to find the root cause to get you better and stay pain-free. Each patient’s condition is unique, and your condition will be treated effectively/efficiently. Let’s <span id="bounceBackText">BOUNCE BACK</span> into health and your full potential!</p>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map / Office Hours */}

            <Map/>

        </>
    )
}

export default SportsInjury;