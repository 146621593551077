// general imports

import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Book.css';

const Book = (props) => {

    return (

        <>
        
            <div className="bookAppointmentDiv">
                <Link to="/contact" style={{textDecoration:"none"}}><p>Book An Appointment</p></Link>
            </div>

        </>

    )
}

export default Book;