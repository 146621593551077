// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Headaches.css';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';
import BackButton from "./BackButton";

// img imports
import headachePic from './img/headache.png';

const Headaches = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book/>

            {/* Header Div */}

            <div className='headacheHeader'>
                <div className="headacheText">
                    <h1>Headaches</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <BackButton/>
            </div>

            {/* headaches */}

            <div className="headacheImageDiv">
                <img src={headachePic} alt="headache pic" id="headachePic"/>
            </div>

            <div className="headacheDiv">
                <p>Headaches are typically described as tightness or a band around your head, pain behind your eyes, or a constant ache at the base of your skull. Essentially, your brain and all your spinal nerves branch from small holes in the skull. Common conditions with pinched nerves around the skull can cause headaches, Bell’s Palsy, TMJ complications, chronic twitching of eyelids, sensitive scalp, ringing in ears, blurry vision, and staining of the eyes.</p>
                <p>Common causes of headaches include stress, trauma, whiplash, poor posture, incorrect cervical curve in the spine, and referral to the neck, shoulder, arms, and fingers. Left untreated, the headaches can progress into stronger symptoms and eventually chronic migraines. Sadly, because of headaches, patients tend to use “band-aid solutions” such as medications that can wear away at the liver, kidneys, and stomach/intestinal membranes (ulcers).</p>
                <p>Our team will individualize a care plan dedicated to your headaches based on your condition to find the root cause to get you better and stay pain-free. Each patient’s condition is unique, and your condition will be treated effectively/efficiently. Let’s <span id="bounceBackText">BOUNCE BACK</span> into health and your full potential!</p>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map / Office Hours */}

            <Map/>

        </>
    )
}

export default Headaches;