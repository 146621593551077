// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Services.css';

// img imports
import phone from './img/phoneIcon.png';
import backgroundVideo from './img/backgroundVideo.mp4';
import acupuncture from './img/acupuncture.png';
import cryo from './img/cryo.png';
import hydration from './img/hydration.png';
import injury from './img/injury.png';
import prp from './img/prp.png';
import rehab from './img/rehab.png';
import therapy from './img/therapy.png';
import athletic from './img/athletic.png';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';

const Services = (props) => {

    return (

        <>

            <Book />

            {/* Header Div */}

            <div className='servicesHeader'>
                <div className="servicesText">
                    <h1>Services</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
            </div>

            {/* Services */}

            <div className="chiroVideoDiv">
                <div className="chiroCol">
                    <div className="chiroText">
                        <h2>Chiropractic</h2>
                    </div>
                </div>
                <div className="chiroCol">
                    <div className="chiroVideo">
                        <video autoPlay loop muted playsInline id="chiroVideo" src={backgroundVideo} type="video/mp4">
                        </video>
                    </div>
                </div>
            </div>

            <div className="servicesDiv">
                <div className="servicesRow">
                    <div className="servicesCol">
                        <div className="serviceContainer">
                            <img src={acupuncture} alt="acupuncture photo" className="servicePhoto" />
                            <h3 id="acupunctureText">Acupuncture</h3>
                        </div>
                    </div>
                    <div className="servicesCol">
                        <div className="serviceContainer mobileContainer">
                            <img src={cryo} alt="cryo photo" className="servicePhoto" />
                            <h3 id="cryoText">Cryo Therapy / Laser Therapy</h3>
                        </div>
                    </div>
                    <div className="servicesCol">
                        <div className="serviceContainer mobileContainer">
                            <img src={hydration} alt="hydration photo" className="servicePhoto" />
                            <h3 id="hydrationText">I V Hydration Therapy</h3>
                        </div>
                    </div>
                    <div className="servicesCol">
                        <div className="serviceContainer mobileContainer">
                            <img src={injury} alt="injury photo" className="servicePhoto" />
                            <h3 id="personalInjuryText">Personal Injury</h3>
                        </div>
                    </div>
                </div>
                <div className="servicesRow">
                    <div className="servicesCol">
                        <div className="serviceContainer">
                            <img src={prp} alt="prp photo" className="servicePhoto" />
                            <h3>PRP Therapy</h3>
                        </div>
                    </div>
                    <div className="servicesCol">
                        <div className="serviceContainer mobileContainer">
                            <img src={rehab} alt="rehab photo" className="servicePhoto" />
                            <h3>Rehabilitation</h3>
                        </div>
                    </div>
                    <div className="servicesCol">
                        <div className="serviceContainer mobileContainer">
                            <img src={therapy} alt="therapy photo" className="servicePhoto" />
                            <h3 id="physicalTherapyText">Physical Therapy</h3>
                        </div>
                    </div>
                    <div className="servicesCol">
                        <div className="serviceContainer mobileContainer">
                            <img src={athletic} alt="athletic photo" className="servicePhoto" />
                            <h3>Athletic Care</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div id="servicesDivMobile">
                <div className="mobileColumn leftColumn">
                    <div className="mobileRow">
                        <div className="mobileContainer">
                            <img src={acupuncture} alt="acupuncture photo" className="servicePhoto" />
                            <h3 id="acupunctureText">Acupuncture</h3>
                        </div>
                    </div>
                    <div className="mobileRow">
                        <div className="mobileContainer">
                            <img src={cryo} alt="cryo photo" className="servicePhoto" />
                            <h3 id="cryoText">Cryo Therapy / Laser Therapy</h3>
                        </div>
                    </div>
                    <div className="mobileRow">
                        <div className="mobileContainer">
                            <img src={hydration} alt="hydration photo" className="servicePhoto" />
                            <h3 id="hydrationText">I V Hydration Therapy</h3>
                        </div>
                    </div>
                    <div className="mobileRow">
                        <div className="mobileContainer">
                            <img src={injury} alt="injury photo" className="servicePhoto" />
                            <h3 id="personalInjuryText">Personal Injury</h3>
                        </div>
                    </div>
                </div>
                <div className="mobileColumn">
                    <div className="mobileRow">
                        <div className="mobileContainer">
                            <img src={prp} alt="prp photo" className="servicePhoto" />
                            <h3>PRP Therapy</h3>
                        </div>
                    </div>
                    <div className="mobileRow">
                        <div className="mobileContainer">
                            <img src={rehab} alt="rehab photo" className="servicePhoto" />
                            <h3>Rehabilitation</h3>
                        </div>
                    </div>
                    <div className="mobileRow">
                        <div className="mobileContainer">
                            <img src={therapy} alt="therapy photo" className="servicePhoto" />
                            <h3 id="physicalTherapyText">Physical Therapy</h3>
                        </div>
                    </div>
                    <div className="mobileRow">
                        <div className="mobileContainer">
                            <img src={athletic} alt="athletic photo" className="servicePhoto" />
                            <h3>Athletic Care</h3>
                        </div>
                    </div>
                </div>
            </div>

            {/* Providers */}

            <Providers />

            {/* Map (Mobile) */}

            <Map />

        </>
    )
}

export default Services;