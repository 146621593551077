// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Map.css';

const Map = (props) => {

    return (

        <>

            {/* Office Hours (mobile) */}

            <div className="officeHoursMobile">
                <div className="hoursHeader">
                    <p>Office Hours</p>
                </div>
                <div className="hoursDisplay">
                    <table class="table" id="hoursTable">
                        <tbody>
                            <tr>
                                <td className="mobileTableLabel">Monday</td>
                                <td className="mobileTableFont">8:00 am - 12:00 pm | 3:00 pm - 6:00 pm</td>
                            </tr>
                            <tr>
                                <td className="mobileTableLabel">Tuesday</td>
                                <td className="mobileTableFont">8:00 am - 12:00 pm | 3:00 pm - 6:00 pm</td>
                            </tr>
                            <tr>
                                <td className="mobileTableLabel">Wednesday</td>
                                <td className="mobileTableFont">8:00 am - 12:00 pm | 3:00 pm - 6:00 pm</td>
                            </tr>
                            <tr>
                                <td className="mobileTableLabel">Thursday</td>
                                <td className="mobileTableFont">8:00 am - 12:00 pm | 3:00 pm - 6:00 pm</td>
                            </tr>
                            <tr>
                                <td className="mobileTableLabel">Saturday</td>
                                <td className="mobileTableFont">8:00 am - 12:00 pm, by appointment only</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Map (Mobile) */}

            <div className="mapDiv">

                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2984.0498602265825!2d-87.8792376845037!3d41.58980407924608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e40230601e2bb%3A0x472f0e650543158c!2s16525%20106th%20Ct%2C%20Orland%20Park%2C%20IL%2060467!5e0!3m2!1sen!2sus!4v1663255767580!5m2!1sen!2sus"
                    width="340"
                    height="360"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    className="mobileMapSmall">
                </iframe>

                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2984.0498602265825!2d-87.8792376845037!3d41.58980407924608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e40230601e2bb%3A0x472f0e650543158c!2s16525%20106th%20Ct%2C%20Orland%20Park%2C%20IL%2060467!5e0!3m2!1sen!2sus!4v1663255767580!5m2!1sen!2sus"
                    width="360"
                    height="360"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    className="mobileMap">
                </iframe>

                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2984.0498602265825!2d-87.8792376845037!3d41.58980407924608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e40230601e2bb%3A0x472f0e650543158c!2s16525%20106th%20Ct%2C%20Orland%20Park%2C%20IL%2060467!5e0!3m2!1sen!2sus!4v1663255767580!5m2!1sen!2sus"
                    width="750"
                    height="360"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    className="ipadMap">
                </iframe>

            </div>



            {/* Map (Desktop) */}

            <div className="mapDesktopDiv">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2984.0498602265825!2d-87.8792376845037!3d41.58980407924608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e40230601e2bb%3A0x472f0e650543158c!2s16525%20106th%20Ct%2C%20Orland%20Park%2C%20IL%2060467!5e0!3m2!1sen!2sus!4v1663255767580!5m2!1sen!2sus"
                    width="1200"
                    height="360"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    className="desktopMap">
                </iframe>

                {/* Office Hours (Desktop) */}

                <div className="officeHours">
                    <div className="hoursHeader">
                        <p>Office Hours</p>
                    </div>
                    <div className="hoursDisplay">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td>Monday</td>
                                    <td className="desktopTableFont">8:00 am - 12:00 pm | 3:00 pm - 6:00 pm</td>
                                </tr>
                                <tr>
                                    <td>Tuesday</td>
                                    <td className="desktopTableFont">8:00 am - 12:00 pm | 3:00 pm - 6:00 pm</td>
                                </tr>
                                <tr>
                                    <td>Wednesday</td>
                                    <td className="desktopTableFont">8:00 am - 12:00 pm | 3:00 pm - 6:00 pm</td>
                                </tr>
                                <tr>
                                    <td>Thursday</td>
                                    <td className="desktopTableFont">8:00 am - 12:00 pm | 3:00 pm - 6:00 pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td className="desktopTableFont">8:00 am - 12:00 pm, by appointment only</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Map;