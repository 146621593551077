// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Hamburger from 'hamburger-react';
import './Navbar.css';

// component imports
import Menu from "./Menu";

// img imports
import phone from './img/phoneIcon.png';
import logo from './img/logoV2.png';

const Navbar = (props) => {

    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    return (

        <>

            {/* Hamburger Menu - Only visible on mobile */}

            <div className="HamburgerMenuDiv" style={{ display: hamburgerOpen ? 'inline' : 'none' }}>
                <Menu hamburgerstatus={hamburgerOpen} sethamburgerstatus={setHamburgerOpen} />
            </div>

            {/* Navbar */}

            <div className="topBackground"></div>

            <nav>
                <div className="navbar">
                    <div className="logo">
                        <Link to="/"><img src={logo} alt="logo" id="bblogo" /></Link>
                    </div>
                    <div className="navLinks">
                        <ul className="links">
                            <li><Link to="/" className="navigationLink">Home</Link></li>
                            <li><Link to="/about" className="navigationLink">About Us</Link></li>
                            <li><Link to="/services" className="navigationLink">Services</Link></li>
                            <li><Link to="/symptoms" className="navigationLink">Symptoms</Link></li>
                            <li><Link to="/testimonials" className="navigationLink">Testimonials</Link></li>
                            <li><Link to="/contact" className="navigationLink">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="phoneDiv">
                        <img src={phone} alt="phoneIcon" id="phoneIcon" />
                        <p id="phoneNumber">(708) 590 - 6302</p>
                    </div>
                    <div className="hamburgerContainer">
                        <Hamburger color="#608A00" duration={0.7} rounded onToggle={toggled => {
                            if (toggled) {
                                setHamburgerOpen(!hamburgerOpen)
                            } else {
                                setHamburgerOpen(!hamburgerOpen)
                            }
                        }} />
                    </div>
                </div>
            </nav>

        </>

    )
}

export default Navbar;