// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './BackButton.css';

const BackButton = (props) => {

    return (

        <>
        
            <a href="/symptoms" className="backButton">Back to Symptoms</a>
        
        </>
    )
}

export default BackButton;