import React, { useState } from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import Home from './components/Home';
import Navbar from './components/Navbar';
import About from './components/About';
import Services from './components/Services';
import Symptoms from './components/Symptoms';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import PersonalInjury from './components/PersonalInjury';
import SportsInjury from './components/SportsInjury';
import Whiplash from './components/Whiplash';
import Sciatica from './components/Sciatica';
import Numbness from './components/Numbness';
import Sleep from './components/Sleep';
import Headaches from './components/Headaches';
import Migraines from './components/Migraines';
import NeckPain from './components/NeckPain';
import ShoulderPain from './components/ShoulderPain';
import UpperBack from './components/UpperBack';
import LowerBack from './components/LowerBack';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Navbar/>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home></Home>}></Route>
          <Route path='/about' element={<About></About>}></Route>
          <Route path='/services' element={<Services></Services>}></Route>
          <Route path='/symptoms' element={<Symptoms></Symptoms>}></Route>
          <Route path='/testimonials' element={<Testimonials></Testimonials>}></Route>
          <Route path='/contact' element={<Contact></Contact>}></Route>
          <Route path='/personal' element={<PersonalInjury></PersonalInjury>}></Route>
          <Route path='/sports' element={<SportsInjury></SportsInjury>}></Route>
          <Route path='/whiplash' element={<Whiplash></Whiplash>}></Route>
          <Route path='/sciatica' element={<Sciatica></Sciatica>}></Route>
          <Route path='/numbness' element={<Numbness></Numbness>}></Route>
          <Route path='/sleep' element={<Sleep></Sleep>}></Route>
          <Route path='/headaches' element={<Headaches></Headaches>}></Route>
          <Route path='/migraines' element={<Migraines></Migraines>}></Route>
          <Route path='/neckpain' element={<NeckPain></NeckPain>}></Route>
          <Route path='/shoulderpain' element={<ShoulderPain></ShoulderPain>}></Route>
          <Route path='/upperback' element={<UpperBack></UpperBack>}></Route>
          <Route path='/lowerback' element={<LowerBack></LowerBack>}></Route>
        </Routes>
      </div>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
