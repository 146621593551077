// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './UpperBack.css';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';
import BackButton from "./BackButton";

// img imports
import upperbackPic from './img/upperBack.png';

const UpperBack = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book/>

            {/* Header Div */}

            <div className='upperbackHeader'>
                <div className="upperbackText">
                    <h1>Upper Back Pain</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <BackButton/>
            </div>

            {/* Upper Back Pain */}

            <div className="upperbackImageDiv">
                <img src={upperbackPic} alt="upper back pic" id="upperbackPic"/>
            </div>

            <div className="upperbackDiv">
                <p>Upper back pain is typically a result of poor posture, scoliosis, or an old injury that healed improperly. This pain can be a constant nagging ache that you may have gotten used to or it is progressively compressing on vitals structures and nerves. Whiplash conditions can also contribute and refer to the upper back.  Upper back pain can cause a downhill effect to surrounding structures in the shoulders, midback, and lower back/hips. Don’t delay on fixing your spinal misalignments in your upper back.</p>
                <p>Our team will individualize a care plan dedicated to your upper back pain based on your condition to find the root cause to get you better and stay pain-free. Each patient’s condition is unique, and your condition will be treated effectively/efficiently. Let’s <span id="bounceBackText">BOUNCE BACK</span> into health and your full potential!</p>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map / Office Hours */}

            <Map/>

        </>
    )
}

export default UpperBack;