// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Sciatica.css';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';
import BackButton from "./BackButton";

// img imports
import sciaticaPic from './img/sciatica.png';

const Sciatica = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book/>

            {/* Header Div */}

            <div className='sciaticaHeader'>
                <div className="sciaticaText">
                    <h1>Sciatica</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <BackButton/>
            </div>

            {/* Personal Injury */}

            <div className="sciaticaImageDiv">
                <img src={sciaticaPic} alt="sciatica pic" id="sciaticaPic"/>
            </div>

            <div className="sciaticaDiv">
                <p>Sciatica is compression or pinching placed on the spinal nerve that branches off your lower back. This gigantic nerve branches off multiple times into smaller nerve roots and fibers that enter your buttocks, hips, legs, and toes.</p>
                <p>When the sciatic nerve becomes irritated, it can cause shooting waves of referral pain in the back and anywhere down and along the leg to the feet. Simple motions such as sitting, getting up from a chair, walking, or laying down to sleep can worsen and aggravate the pain. Sometimes this nerve can be pinched by muscle spasms, incorrect spinal curvature, or most commonly a lumbar disc herniation. With time, this condition will worsen until it is properly corrected.</p>
                <p>Our team will individualize a care plan dedicated to your sciatica based on your condition to find the root cause to get you better and stay pain-free. Each patient’s condition is unique, and your condition will be treated effectively/efficiently. Let’s <span id="bounceBackText">BOUNCE BACK</span> into health and your full potential!</p>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map / Office Hours */}

            <Map/>

        </>
    )
}

export default Sciatica;