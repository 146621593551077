// General imports
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Home.css';

// img imports
import phone from './img/phoneIcon.png';
import backgroundVideo from './img/backgroundVideo.mp4';
import playIcon from './img/playIconTransparent.png';
import day1 from './img/dayOnePic.png';
import day2 from './img/consult.png';
import day3 from './img/dayThreePic.png';
import day1mobile from './img/dayOneMobile.png';
import day2mobile from './img/dayTwoMobile.png';
import day3mobile from './img/dayThreeMobile.png';
import grandOpening from './img/GrandOpeningVOne.jpeg';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';

const Home = (props) => {

    function handleLearnButton() {
        window.open("https://en.wikipedia.org/wiki/Chiropractic", '_blank', 'noopener', 'noreferrer');
    };

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowPopup(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (

        <>

            {/* Book Appointment Button */}

            <Book />

            {/* Background Video */}


            <div className='backgroundVidDiv'>

                {showPopup && (
                    <div className="popup">
                        <div id="closeCircle" onClick={() => setShowPopup(false)}>X</div>
                        <img src={grandOpening} alt="Grand Opening Banner" id="grandOpeningImage"/>
                    </div>
                )}

                <video autoPlay loop muted playsInline id="backgroundVideo" src={backgroundVideo} type="video/mp4"></video>
                <div className="overlay"></div>
                <div className="phoneDivMobile">
                    <img src={phone} alt="phoneIcon" id="phoneIcon" />
                    <p id="phoneNumber">(312) 569-0356</p>
                </div>
                <div className="learnDiv">
                    <div className="learnText">
                        <h3>What is</h3>
                        <h3>Chiropractic Medicine?</h3>
                    </div>
                    <div className="learnButton" onClick={handleLearnButton}>
                        <img src={playIcon} alt="play Icon" id="playIconSize" />
                        <p>Learn</p>
                    </div>
                </div>
                <div class="custom-shape-divider-bottom-1646980040">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                    </svg>
                </div>
                <div class="custom-shape-divider-bottom-1646855548">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                    </svg>
                </div>
            </div>

            {/* Days */}

            <div className="daysDiv">
                <div className="daysHeader">
                    <h2>A DAY-BY-DAY</h2>
                    <h2>APPROACH</h2>
                </div>
                <div className="alignmentDiv">
                    <div className="dayDiv dayOne">
                        <img src={day1} alt="day1 image" className="dayIcon" />
                        <div className="dayInfo dayOneInfo">
                            <h4>Day 1</h4>
                            <p>Book an appointment to have a full chiropractic examination performed. This includes history and exam, X-rays (if medically necessary), and collection of insurance information to check your plan coverage for chiropractic.</p>
                        </div>
                    </div>
                    <div className="dayDiv dayTwo">
                        <img src={day2} alt="day2 image" className="dayIcon" id="dayTwoImage" />
                        <div className="dayInfo dayTwoInfo">
                            <h4>Day 2</h4>
                            <p>Consultation and report of findings based off your history, examination, and X-rays (if performed). This also includes explanation of benefits based off your medical insurance coverage. We also have cash options with no-interest financing if there is not any coverage or you do not have medical insurance.</p>
                        </div>
                    </div>
                    <div className="dayDiv dayThree">
                        <img src={day3} alt="day3 image" className="dayIcon" />
                        <div className="dayInfo dayThreeInfo">
                            <h4 className="dayThreeTextHeader">Day 3</h4>
                            <p className="dayThreeTextBody">Let’s start and <span id="bouncebackText">BOUNCE BACK</span> into health and your full potential with your personalized care plan. Enjoy a pain-free lifestyle!</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Days (mobile) */}

            <div className="daysDivMobile">
                <div className="daysHeader">
                    <h2>A DAY-BY-DAY</h2>
                    <h2>APPROACH</h2>
                </div>
                <div className="alignmentDiv">
                    <div className="dayDiv dayOne">
                        <img src={day1mobile} alt="day1 image" className="dayIcon" />
                        <div className="dayInfo dayOneInfo">
                            <p>Book an appointment to have a full chiropractic examination performed. This includes history and exam, X-rays (if medically necessary), and collection of insurance information to check your plan coverage for chiropractic.</p>
                        </div>
                    </div>
                    <div className="dayDiv dayTwo">
                        <img src={day2mobile} alt="day2 image" className="dayIcon" id="dayTwoImage" />
                        <div className="dayInfo">
                            <p>Consultation and report of findings based off your history, examination, and X-rays (if performed). This also includes explanation of benefits based off your medical insurance coverage. We also have cash options with no-interest financing if there is not any coverage or you do not have medical insurance.</p>
                        </div>
                    </div>
                    <div className="dayDiv dayThree">
                        <img src={day3mobile} alt="day3 image" className="dayIcon" />
                        <div className="dayInfo">
                            <p className="dayThreeTextBody">Let’s start and <span id="bouncebackText">BOUNCE BACK</span> into health and your full potential with your personalized care plan. Enjoy a pain-free lifestyle!</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Providers */}

            <Providers />

            {/* Map */}

            <Map />

        </>
    )
}

export default Home;