// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Symptoms.css';

// img imports
import injury from './img/injury.png';
import sportsInjury from './img/sportsInjury.png';
import whiplash from './img/whiplash.png';
import sciatica from './img/sciatica.png';
import numbness from './img/numbness.png';
import sleep from './img/sleep.png';
import headache from './img/headache.png';
import migraine from './img/migraine.png';
import neckpain from './img/neckPain.png';
import shoulderpain from './img/shoulderPain.png';
import upperback from './img/upperBack.png';
import lowerback from './img/lowerBack.png';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';

const Symptoms = (props) => {

    return (

        <>

            <Book/>

            {/* Header Div */}

            <div className='symptomsHeader'>
                <div className="symptomsText">
                    <h1>Symptoms</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
            </div>

            {/* Symptoms Div */}

            <div className="symptomsDiv">
                <div className="symptomsRow">
                    <div className="symptomsCol">
                        <div className="symptomsContainer">
                            <Link to="/neckpain"><img src={neckpain} alt="neck pain photo" className="symptomPhoto" /></Link>
                            <Link to="/neckpain" className="linkText">Neck Pain</Link>
                        </div>
                    </div>
                    <div className="symptomsCol">
                        <div className="symptomsContainer">
                            <Link to="/upperback"><img src={upperback} alt="upper back photo" className="symptomPhoto" /></Link>
                            <Link to="/upperback" className="linkText">Upper Back Pain</Link>
                        </div>
                    </div>
                    <div className="symptomsCol">
                        <div className="symptomsContainer">
                            <Link to="/lowerback"><img src={lowerback} alt="lower back photo" className="symptomPhoto" /></Link>
                            <Link to="/lowerback" className="linkText">Lower Back Pain</Link>
                        </div>
                    </div>
                    <div className="symptomsCol">
                        <div className="symptomsContainer">
                            <Link to="/headaches"><img src={headache} alt="headache photo" className="symptomPhoto" /></Link>
                            <Link to="/headaches" className="linkText">Headaches</Link>
                        </div>
                    </div>
                </div>
                <div className="symptomsRow">
                    <div className="symptomsCol">
                        <div className="symptomsContainer">
                            <Link to="/migraines"><img src={migraine} alt="migraine photo" className="symptomPhoto" /></Link>
                            <Link to="/migraines" className="linkText">Migraines</Link>
                        </div>
                    </div>
                    <div className="symptomsCol">
                        <div className="symptomsContainer">
                            <Link to="/shoulderpain"><img src={shoulderpain} alt="shoulder pain photo" className="symptomPhoto" /></Link>
                            <Link to="/shoulderpain" className="linkText">Shoulder Pain</Link>
                        </div>
                    </div>
                    <div className="symptomsCol">
                        <div className="symptomsContainer">
                            <Link to="/numbness"><img src={numbness} alt="numbness photo" className="symptomPhoto" /></Link>
                            <Link to="/numbness" className="linkText">Numbness and Tingling</Link>
                        </div>
                    </div>
                    <div className="symptomsCol">
                        <div className="symptomsContainer">
                            <Link to="/sciatica"><img src={sciatica} alt="sciatica photo" className="symptomPhoto" /></Link>
                            <Link to="/sciatica" className="linkText">Sciatica</Link>
                        </div>
                    </div>
                </div>
                <div className="symptomsRow">
                    <div className="symptomsCol">
                        <div className="symptomsContainer">
                            <Link to="/sleep"><img src={sleep} alt="sleep photo" className="symptomPhoto" /></Link>
                            <Link to="/sleep" className="linkText">Sleep Issues</Link>
                        </div>
                    </div>
                    <div className="symptomsCol">
                        <div className="symptomsContainer">
                            <Link to="/whiplash"><img src={whiplash} alt="whiplash photo" className="symptomPhoto" /></Link>
                            <Link to="/whiplash" className="linkText">Whiplash</Link>
                        </div>
                    </div>
                    <div className="symptomsCol">
                        <div className="symptomsContainer">
                            <Link to="/personal"><img src={injury} alt="personal injury photo" className="symptomPhoto"/></Link>
                            <Link to="/personal" className="linkText">Personal Injury</Link>
                        </div>
                    </div>
                    <div className="symptomsCol">
                        <div className="symptomsContainer">
                            <Link to="/sports"><img src={sportsInjury} alt="sports injury photo" className="symptomPhoto" /></Link>
                            <Link to="/sports" className="linkText">Sports Injury</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* mobile symptoms div */}

            <div className="symptomsDivMobile">
                <div className="symptomsContainer">
                    <Link to="/neckpain"><img src={neckpain} alt="neck pain photo" className="symptomPhoto" /></Link>
                    <Link to="/neckpain" className="linkText">Neck Pain</Link>
                </div>
                <div className="symptomsContainer">
                    <Link to="/upperback"><img src={upperback} alt="upper back photo" className="symptomPhoto" /></Link>
                    <Link to="/upperback" className="linkText">Upper Back Pain</Link>
                </div>
                <div className="symptomsContainer">
                    <Link to="/lowerback"><img src={lowerback} alt="lower back photo" className="symptomPhoto" /></Link>
                    <Link to="/lowerback" className="linkText">Lower Back Pain</Link>
                </div>
                <div className="symptomsContainer">
                    <Link to="/headaches"><img src={headache} alt="headache photo" className="symptomPhoto" /></Link>
                    <Link to="/headaches" className="linkText">Headaches</Link>
                </div>
                <div className="symptomsContainer">
                    <Link to="/migraines"><img src={migraine} alt="migraine photo" className="symptomPhoto" /></Link>
                    <Link to="/migraines" className="linkText">Migraines</Link>
                </div>
                <div className="symptomsContainer">
                    <Link to="/shoulderpain"><img src={shoulderpain} alt="shoulder pain photo" className="symptomPhoto" /></Link>
                    <Link to="/shoulderpain" className="linkText">Shoulder Pain</Link>
                </div>
                <div className="symptomsContainer">
                    <Link to="/numbness"><img src={numbness} alt="numbness photo" className="symptomPhoto" /></Link>
                    <Link to="/numbness" className="linkText">Numbness and Tingling</Link>
                </div>
                <div className="symptomsContainer">
                    <Link to="/sciatica"><img src={sciatica} alt="sciatica photo" className="symptomPhoto" /></Link>
                    <Link to="/sciatica" className="linkText">Sciatica</Link>
                </div>
                <div className="symptomsContainer">
                    <Link to="/sleep"><img src={sleep} alt="sleep photo" className="symptomPhoto" /></Link>
                    <Link to="/sleep" className="linkText">Sleep Issues</Link>
                </div>
                <div className="symptomsContainer">
                    <Link to="/whiplash"><img src={whiplash} alt="whiplash photo" className="symptomPhoto" /></Link>
                    <Link to="/whiplash" className="linkText">Whiplash</Link>
                </div>
                <div className="symptomsContainer">
                    <Link to="/personal"><img src={injury} alt="personal injury photo" className="symptomPhoto" /></Link>
                    <Link to="/personal" className="linkText">Personal Injury</Link>
                </div>
                <div className="symptomsContainer sportsContainer">
                    <Link to="/sports"><img src={sportsInjury} alt="sports injury photo" className="symptomPhoto" /></Link>
                    <Link to="/sports" className="linkText">Sports Injury</Link>
                </div>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map */}

            <Map/>

        </>
    )
}

export default Symptoms;