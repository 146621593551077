// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './LowerBack.css';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';
import BackButton from "./BackButton";

// img imports
import lowerbackPic from './img/lowerBack.png';

const LowerBack = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book/>

            {/* Header Div */}

            <div className='lowerbackHeader'>
                <div className="lowerbackText">
                    <h1>Lower Back Pain</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <BackButton/>
            </div>

            {/* Lower Back */}

            <div className="lowerbackImageDiv">
                <img src={lowerbackPic} alt="lower back pic" id="lowerbackPic"/>
            </div>

            <div className="lowerbackDiv">
                <p>Low back pain is one of the most common and chronic conditions seen. Chronic low back pain can develop into structural issues such as a disc protrusion/herniation. Typically, people refer to disc issues as a ruptured disc, torn disc, or slipped disc. More importantly, at times, disc related conditions can lay dormant or show only as a muscle spasm until you are ultimately stuck in a hunched, crooked position and unable to stand straight.</p>
                <p>The lower back has the primary role of maintaining and supporting the stability of your entire spine, hips, and legs! The lower back has very large nerves that break off into finer nerve roots to the stomach and intestines causing digestive issues such as diarrhea, constipation, and bloating. These same nerves travel all the way down to your toes. Simple movements such as bending to tie your shoes, turning over while sleeping in bed, or getting up from a chair can cause intense muscle spasms and shooting pain down your legs. Other condition can come from motor vehicle accidents and sports injuries. If these structural misalignments are not corrected quickly, this can cause permanent conditions such as a disc herniation and chronic muscle spasms.</p>
                <p>Our team will individualize a care plan dedicated to your lower back pain based on your condition to find the root cause to get you better and stay pain-free. Each patient’s condition is unique, and your condition will be treated effectively/efficiently. Let’s <span id="bounceBackText">BOUNCE BACK</span> into health and your full potential!</p>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map / Office Hours */}

            <Map/>

        </>
    )
}

export default LowerBack;