// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Migraines.css';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';
import BackButton from "./BackButton";

// img imports
import migrainesPic from './img/migraine.png';

const Migraines = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book/>

            {/* Header Div */}

            <div className='migrainesHeader'>
                <div className="migrainesText">
                    <h1>Migraines</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <BackButton/>
            </div>

            {/* migraines */}

            <div className="migrainesImageDiv">
                <img src={migrainesPic} alt="migraines pic" id="migrainesPic"/>
            </div>

            <div className="migrainesDiv">
                <p>Suffering from migraines, it’s often very difficult to treat or get pain relief. Migraines can be completely debilitating and can worsen with each episode. Typically, you can feel it is difficult to perform basic daily tasks and are sensitive to light, noise, and smells. They can also become nauseating and result in persistent vomiting. </p>
                <p>Common causes of migraines include stress, trauma, whiplash, poor posture, incorrect cervical curve in the spine, and muscle tension. Migraines can begin with neurological symptoms like an aura of flashing lights or bright/dark spots in vision. Within 30 minutes, these debilitating migraines can last from 1 hour to 3 days in which medical interventions/drugs may be needed if not treated quickly with chiropractic adjustments. </p>
                <p>Our team will individualize a care plan dedicated to your migraines based on your condition to find the root cause to get you better and stay pain-free. Each patient’s condition is unique, and your condition will be treated effectively/efficiently. Let’s <span id="bounceBackText">BOUNCE BACK</span> into health and your full potential!</p>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map / Office Hours */}

            <Map/>

        </>
    )
}

export default Migraines;