// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Numbness.css';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';
import BackButton from "./BackButton";

// img imports
import numbnessPic from './img/numbness.png';

const Numbness = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book/>

            {/* Header Div */}

            <div className='numbnessHeader'>
                <div className="numbnessText">
                    <h1>Numbness and Tingling</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <BackButton/>
            </div>

            {/* Numbness */}

            <div className="numbnessImageDiv">
                <img src={numbnessPic} alt="Numbness and tingling pic" id="numbnessPic"/>
            </div>

            <div className="numbnessDiv">
                <p>Numbness and tingling can happen anywhere and anytime in the body, but it is most common to notice it in your hands, arms, and legs. This can range from a slight annoyance that comes and goes, chronic mild small areas of numbness/burning, or mentally draining and unbearable.</p>
                <p>Our team will individualize a care plan dedicated to your numbness and tingling based on your condition to find the root cause to get you better and stay pain-free. Each patient’s condition is unique, and your condition will be treated effectively/efficiently. Let’s <span id="bounceBackText">BOUNCE BACK</span> into health and your full potential!</p>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map / Office Hours */}

            <Map/>

        </>
    )
}

export default Numbness;