// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Whiplash.css';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';
import BackButton from './BackButton';

// img imports
import whiplashPic from './img/whiplash.png';

const Whiplash = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book/>

            {/* Header Div */}

            <div className='whiplashHeader'>
                <div className="whiplashText">
                    <h1>Whiplash</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <BackButton/>
            </div>

            {/* Personal Injury */}

            <div className="whiplashImageDiv">
                <img src={whiplashPic} alt="whiplash pic" id="whiplashPic"/>
            </div>

            <div className="whiplashDiv">
                <p>Whiplash is known as a condition where there is a quick jerking motion of the head that strains the muscles, joints, and nerves of the neck, shoulder, and upper back. This condition is typically overlooked, but this condition is very serious. Whiplash can cause chronic headaches, migraines, nausea, limited ability to turn your head, chronic muscle stiffness/aches in the head and neck, interrupted fields of vision, and much more.</p>
                <p>Often, this is seen after a motor vehicle accident by hitting your head on impact. Also, it can be something simple as riding on a roller coaster, stopping to fast from hitting that car in front of you, or small fender bender while waiting at the stop light. </p>
                <p>Our team will individualize a care plan dedicated to your whiplash based on your condition to find the root cause to get you better and stay pain-free. Each patient’s condition is unique, and your condition will be treated effectively/efficiently. Let’s <span id="bounceBackText">BOUNCE BACK</span> into health and your full potential!</p>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map / Office Hours */}

            <Map/>

        </>
    )
}

export default Whiplash;