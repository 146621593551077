// General imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import './Contact.css';

// img imports
import phone from './img/phoneIcon.png';
import greenPhone from './img/greenPhoneIcon.png';
import greenEmail from './img/greenEmailIcon.png';
import greenMap from './img/greenMapIcon.png';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';

const Contact = (props) => {

    const [contactPopup, setContactPopup] = useState(false);

    const handleContactPopup = () => {
        setContactPopup(!contactPopup);
    }

    const handleContactClosePopup = () => {
        setContactPopup(false);
    }

    const [status, setStatus] = useState("Submit");

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        const { name, email, message, messageMobile } = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            message: message.value,
            messageMobile: messageMobile.value
        };
        let response = await fetch("https://bouncebackchiro.com/email/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        });
        setStatus("Submit");
        let result = await response.json();
        alert(result.status);

        navigate('/');
    };

    return (

        <>

            {/* Book an Appointment Button */}

            <Book />

            {/* Header Div */}

            <div className='contactHeader'>
                <div className="contactText">
                    <h1>Contact Us</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
            </div>

            {/* Contact Div */}

            <div className="contactDiv">
                <div className="contactCol">
                    <div className="contactContainer">
                        <div className="contactIconDiv">
                            <img src={greenPhone} alt="phone icon" className="greenPhone" />
                            <p className="contactUsText">Call Us</p>
                        </div>
                        <div className="contactPhoneDiv">
                            <p className="contactUsBottom">(708) 590 - 6302</p>
                        </div>
                    </div>
                </div>
                <div className="contactCol">
                    <div className="contactContainer">
                        <div className="contactIconDiv contactMapDiv">
                            <img src={greenMap} alt="map icon" className="greenMap" />
                            <p className="contactUsText">Visit Us</p>
                        </div>
                        <div className="contactPhoneDiv">
                            <p className="contactUsBottom addressContact">16525 106th Ct <br></br>Orland Park, IL 60467</p>
                        </div>
                    </div>
                </div>
                <div className="contactCol">
                    <div className="contactContainer contactFormDiv">
                        <div className="contactIconDiv" onClick={handleContactPopup}>
                            <img src={greenEmail} alt="email icon" className="greenEmail" />
                            <p className="contactUsText messageText">Message Us</p>
                        </div>
                        <div className="contactPhoneDiv" onClick={handleContactPopup}>
                            <p className="contactUsBottom emailAddress">contact@bouncebackchiro.com</p>
                        </div>
                        {contactPopup && (
                            <>
                                <div id="contactPopup">
                                    <div id="closeButton" onClick={handleContactClosePopup}>
                                        <p>X</p>
                                    </div>
                                    <form name="contactForm" id="contactForm" onSubmit={handleSubmit}>
                                        <div className="contactFormDiv">
                                            <label htmlFor="name" className="label">Name: </label>
                                            <input type="text" name="name" id="name" />
                                        </div>
                                        <div className="contactFormDiv">
                                            <label htmlFor="email" className="label">Email: </label>
                                            <input type="email" name="email" id="email" />
                                        </div>
                                        <div className="contactFormDiv">
                                            <label htmlFor="message" className="label">Message: </label>
                                            <textarea name="message" id="message" cols="60" rows="10"></textarea>
                                            <textarea name="messageMobile" id="messageMobile" cols="42" rows="10"></textarea>
                                        </div>
                                        <button type="submit" id="submitButton">{status}</button>
                                    </form>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* Providers */}

            <Providers />

            {/* Map */}

            <Map />

        </>
    )
}

export default Contact;