// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Sleep.css';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';
import BackButton from "./BackButton";

// img imports
import sleepPic from './img/sleep.png';

const Sleep = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book/>

            {/* Header Div */}

            <div className='sleepHeader'>
                <div className="sleepText">
                    <h1>Sleep Issues</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <BackButton/>
            </div>

            {/* Sleep */}

            <div className="sleepImageDiv">
                <img src={sleepPic} alt="sleep pic" id="sleepPic"/>
            </div>

            <div className="sleepDiv">
                <p>Do you have trouble falling asleep, staying asleep, or feeling constantly drained or tired after getting a full night rest? You may be experiencing these issues due to your nervous system being overexcited without the ability to shut down properly for bedtime. Sleep is extremely important because it allows your body to enter deep relaxation where your body can rest, recover, and reorganize every nerve, muscle, and organ system!</p>
                <p>One reason this can happen is because of an improper alignment in your spine causing pressure on a nerve. This can cause that nerve to not function properly and at times over-stimulating it. These nerves can then relay back to the brain throwing off your natural hormones that aid in sleep. These same nerves can affect the hormones that regulate your thyroid, insulin (diabetes), blood pressure, metabolism, menstrual cycles, and many more.</p>
                <p>Our team will individualize a care plan dedicated to your sleep issues based on your condition to find the root cause to get you better and stay pain-free. Each patient’s condition is unique, and your condition will be treated effectively/efficiently. Let’s <span id="bounceBackText">BOUNCE BACK</span> into health and your full potential!</p>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map / Office Hours */}

            <Map/>

        </>
    )
}

export default Sleep;