// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './ShoulderPain.css';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';
import BackButton from "./BackButton";

// img imports
import shoulderpainPic from './img/shoulderPain.png';

const ShoulderPain = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book/>

            {/* Header Div */}

            <div className='shoulderpainHeader'>
                <div className="shoulderpainText">
                    <h1>Shoulder Pain</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <BackButton/>
            </div>

            {/* Shoulder Pain */}

            <div className="shoulderpainImageDiv">
                <img src={shoulderpainPic} alt="shoulder pain pic" id="shoulderpainPic"/>
            </div>

            <div className="shoulderpainDiv">
                <p>Shoulder pain is typically described as being unrelenting and constantly nagging. This can be due to referral pain from another area. We tend to see the nerves in the neck or upper back can refer that pain. One major condition we see in office is patients suffering from unbearable “frozen shoulder.” This condition is due to pinched nerves in the neck causing muscle dysfunction within the shoulder girdle. It will literally “freeze” all movement of reaching above your head or behind your back. </p>
                <p>Often, pain is also due to structural dysfunctions within the joint. It is hard to believe, but the entire arm is held to your body by 4 ligaments in your shoulder! Dysfunction in these ligaments can be due to tears/thickening, arthritis, and previous injuries. We have all the necessary training needed to recover that shoulder mobility!</p>
                <p>Our team will individualize a care plan dedicated to your shoulder pain based on your condition to find the root cause to get you better and stay pain-free. Each patient’s condition is unique, and your condition will be treated effectively/efficiently. Let’s <span id="bounceBackText">BOUNCE BACK</span> into health and your full potential!</p>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map / Office Hours */}

            <Map/>

        </>
    )
}

export default ShoulderPain;