// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Testimonials.css';

// img imports
import quotationMarks from './img/quotationMarks.png';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';

const Testimonials = (props) => {

    return (

        <>

            <Book/>

            {/* Header Div */}

            <div className='testimonialsHeader'>
                <div className="testimonialsText">
                    <h1>Testimonials</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
            </div>

            {/* Testimonials Div */}

            <div className="testimonialsDiv">
                <div className="quoteHeader">
                    <h2>Hear what our patients have to say</h2>
                    <h3>Coming Soon!</h3>
                </div>
                <div className="quoteBody">
                    <img src={quotationMarks} alt="quotationMarkIcon" className="quotationMarks" />
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure impedit architecto facilis. Praesentium, similique explicabo provident illo incidunt blanditiis. Atque aperiam, sed corrupti ad eos dolore molestiae necessitatibus? Pariatur, libero.</p>
                    <div className="quotationSignature">
                        <p>- John Smith</p>
                    </div>
                    <div className="lineDiv">
                        <hr className="quoteLine" />
                    </div>
                </div>
            </div>

            <div className="testimonialsDiv">
                <div className="quoteBody" id="secondQuote">
                    <img src={quotationMarks} alt="quotationMarkIcon" className="quotationMarks" />
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure impedit architecto facilis. Praesentium, similique explicabo provident illo incidunt blanditiis. Atque aperiam, sed corrupti ad eos dolore molestiae necessitatibus? Pariatur, libero.</p>
                    <div className="quotationSignature">
                        <p>- Jane Doe</p>
                    </div>
                    <div className="lineDiv">
                        <hr className="quoteLine" />
                    </div>
                </div>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map */}

            <Map/>

        </>
    )
}

export default Testimonials;