// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './PersonalInjury.css';

// component imports
import Book from './Book';
import Providers from './Providers';
import Map from './Map';
import BackButton from "./BackButton";

// img imports
import personalInjuryPic from './img/injury.png';

const PersonalInjury = (props) => {

    return (

        <>

            {/* Book an appointment button */}

            <Book/>

            {/* Header Div */}

            <div className='personalInjuryHeader'>
                <div className="personalInjuryText">
                    <h1>Personal Injury</h1>
                </div>
                <div class="custom-shape-divider-top-1646942009">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <BackButton/>
            </div>

            {/* Personal Injury */}

            <div className="personalInjuryImageDiv">
                <img src={personalInjuryPic} alt="personal injury pic" id="personalInjuryPic"/>
            </div>

            <div className="personalInjuryDiv">
                <p>Accidents happen, but the pain and change in your lifestyle following one shouldn’t be your fault and your new normal. Work injuries and motor vehicle injuries can cause your body to develop whiplash, neck pain, referral pain, headaches, lower back pain, and possibly cervical or lumbar disc herniations.</p>
                <p>It is very important for you to seek immediate medical attention in order to prevent further damage. Also, it allows you to have a legal medical record if you decide to pursue a lawsuit. Delaying treatment can cause long term chronic pain and impairment in your normal daily life such as toileting, showering, and working. The longer you wait, the longer it can take to recover and return to your baseline living standards.</p>
                <p>Our team will individualize a care plan dedicated to your personal injury claims based on your condition to find the root cause to get you better and stay pain-free. Each patient’s condition is unique, and your condition will be treated effectively/efficiently. Let’s <span id="bounceBackText">BOUNCE BACK</span> into health and your full potential!</p>
            </div>

            {/* Providers */}

            <Providers/>

            {/* Map / Office Hours */}

            <Map/>

        </>
    )
}

export default PersonalInjury;