// general imports

import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Menu.css';

// image imports
import facebook from './img/facebookLogo.png';
import twitter from './img/twitterLogo.png';
import instagram from './img/instagramLogo.png';


const Menu = (hamburgerstatus, sethamburgerstatus) => {

    return (

        <>

            {/* navigation menu */}
            <div className="burgerMenu">
                <div className="burgerLinks">
                    <Link to="/" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Home</Link>
                    <Link to="/about" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>About Us</Link>
                    <Link to="/services" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Services</Link>
                    <Link to="/symptoms" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Symptoms</Link>
                    <Link to="/testimonials" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Testimonials</Link>
                    <Link to="/contact" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Contact Us</Link>
                </div>

                {/* social media icons */}
                <div className="logoDiv">
                    <a href="https://www.facebook.com/profile.php?id=100083358691602" target="_blank" rel="noopener noreferrer"><img src={facebook} alt='facebook' className="menuLogo"></img></a>
                    <a href="https://www.instagram.com/bounce.back.chiropractic/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt='instagram' className="menuLogo"></img></a>
                    <img src={twitter} alt="twitter logo" className="menuLogo" />
                </div>

                {/* book appointment div */}
                <div className="BurgerBookAppointmentDiv">
                    <Link to="/contact" style={{ textDecoration: "none" }} onClick={() => sethamburgerstatus(!hamburgerstatus)}><p>Book An Appointment</p></Link>
                </div>

            </div>

        </>


    )
}

export default Menu;